import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from '../../public/auth.service';
import { LoginModalComponent } from '../login-modal/login-modal.component';
import { RegisterComponent } from '../register/register.component';

@Component({
  selector: 'mmb-web-app-voting-panel-new',
  templateUrl: './voting-panel.component.html'
})
export class VotingPanelNewComponent implements OnInit {
  @Input() isUpvoteSelected: boolean;
  @Input() isDownvoteSelected: boolean;
  @Input() scorePosition: string;
  @Input() score: number = 0;
  @Output() upvote: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() downvote: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(private authService: AuthService,
    private modalService: NgbModal) { }

  ngOnInit() {
  }

  onUpvoteClicked() {
    if (!this.authService.isAuthenticated$.value) {
      this._openRegisterModal();
      return;
    }
    this.upvote.emit();
  }

  onDownvoteClicked() {
    if (!this.authService.isAuthenticated$.value) {
      this._openRegisterModal();
      return;
    }
    this.downvote.emit();
  }

  getTooltipTextIfLoggedOut() {
    if (!this.authService.isAuthenticated$.value) {
      return 'Sign up or Log in to use this feature';
    }
    return '';
  }

  private _openRegisterModal() {
    const modalRef = this.modalService.open(RegisterComponent, {
      centered: true,
      size: 'md'
    });
    modalRef.componentInstance.isOpenedInModal = true;
    modalRef.componentInstance.completed.subscribe(() => {
      modalRef.dismiss();
    });
  }
}
