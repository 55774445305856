import { Directive, ElementRef, HostListener, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';

/*
Scroll to error directive:
this is a directive that can be implemented anywhere in the app, to in case I'm submitting a form and there is a missing
field, it scrolls to the first field that has an error, we should add the formgroup as an input for the directive in order
to work
*/

@Directive({
  selector: '[mmbWebAppScrollToError]',
  standalone:true
})
export class ScrollToErrorDirective {

  @Input() mmbWebAppScrollToError: FormGroup;
  @Input() showErrorMessage:boolean = false;
  @Input() errorMessage:string = 'Please fill the missing fields';

  constructor(
    private _elementRef: ElementRef,
    private _toastrService : ToastrService
  ) {}

  @HostListener('ngSubmit')
  onFormSubmit() {
    debugger;
    if (this.mmbWebAppScrollToError?.invalid) {
      this.mmbWebAppScrollToError.markAllAsTouched();
      const invalidControl = this._elementRef.nativeElement.querySelector('input.ng-invalid, textarea.ng-invalid, .mmb-custom-field.ng-invalid');
      console.log(invalidControl);
      debugger;
      if (invalidControl) {
        setTimeout(() => {
          this.scrollToElementWithOffset(invalidControl, 120);
        }, 100);
      }
    }
  }

  private scrollToElementWithOffset(element: HTMLElement, offset: number) {
    debugger;

    element.scrollIntoView({
      behavior:'smooth',
      block:'center'
    });

    if(this.showErrorMessage){
      this._toastrService.error(this.errorMessage);
    }
    
  }

}
