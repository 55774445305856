import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'mmb-web-app-inline-svg',
  templateUrl: './inline-svg.component.html'
})
export class InlineSvgComponent implements OnInit {

  @Input() imgURL: string;

  constructor() { }

  ngOnInit() {
  }

}
