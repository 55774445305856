import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'mmb-web-app-join-community',
  templateUrl: './join-community.component.html'
})
export class JoinCommunityComponent {

  @Input() title: string = 'Join the community connecting Africa for more'; 
  @Input() description: string = 'Discover new people & opportunities.<br>Start sharing and collaborating for free.';
  @Input() buttonText: string = 'Join';

}
