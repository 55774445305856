import { Component, OnDestroy, OnInit } from '@angular/core';
import { CommonModule, NgIf } from '@angular/common';
import { GenericLoaderService } from './generic-loader.service';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'mmb-web-app-generic-loader',
  standalone: true,
  imports: [CommonModule, NgIf],
  templateUrl: './generic-loader.component.html',
  styleUrls: ['./generic-loader.component.scss']
})
export class GenericLoaderComponent implements OnInit, OnDestroy {

  private _unsubscribeAll:Subject<null> = new Subject<null>();
  showLoadingBar:boolean = false;
  
  constructor(
    private _genericLoaderService : GenericLoaderService
  ) {
  }

  ngOnInit(): void {
    this.subscribeToLoadingService();
  }

  subscribeToLoadingService(){
    this._genericLoaderService.isPageLoading.pipe(takeUntil(this._unsubscribeAll)).subscribe(
      (res:boolean) => this.showLoadingBar = res
    );
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next(null);
    this._unsubscribeAll.complete();
  }

}
