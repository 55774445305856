import { Location } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'mmb-web-app-item-navigation-panel',
  templateUrl: './item-navigation-panel.component.html'
})
export class ItemNavigationPanelComponent implements OnInit {
  @Input() isBackToTopVisible: boolean;
  @Input() next: string;
  @Input() previous: string;
  @Input() listPageUrl: string;
  @Input() goBackText: string = 'Back';
  @Input() isPrevNextVisible: boolean = true;

  constructor(private router: Router) { }

  ngOnInit() {
  }

  goToTop() {
    var element = document.getElementsByTagName("body")[0];
    element.scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" });
  }

  goBack() {
    this.router.navigate([this.listPageUrl]);
  }
}
