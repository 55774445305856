import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'mmb-web-app-mobile-navigation',
  templateUrl: './mobile-navigation.component.html'
})
export class MobileNavigationComponent implements OnInit {

  @Input() isUserLoggedIn: string;
  @Output() logoutClicked: EventEmitter<void> = new EventEmitter<void>();
  @Output() closeNav: EventEmitter<void> = new EventEmitter<void>();

  public mobile_menu = [
    {
      name: 'About',
      link: '/about',
      isLogged: false,
      isUnLog: true,
    },
    {
      name: 'Careers',
      link: '/careers',
      isLogged: true,
      isUnLog: true,
    },
    {
      name: 'Experiences',
      link: '/experiences',
      isLogged: true,
      isUnLog: true,
    },
    {
      name: 'Events',
      link: '/events',
      isLogged: true,
      isUnLog: true,
    },
    {
      name: 'Requests',
      link: '/requests',
      isLogged: true,
      isUnLog: true,
    },
    {
      name: 'Forum',
      link: '/forum',
      isLogged: true,
      isUnLog: true,
    },
    {
      name: 'Projects',
      link: '/projects',
    },
    {
      name: 'Contracts',
      link: '/contracts',
      isLogged: true,
      isUnLog: true,
    },
    {
      name: 'Insight',
      link: '/insight',
      isLogged: true,
      isUnLog: true,
    },
    {
      name: 'Organisations',
      link: '/organisations',
      isLogged: true,
      isUnLog: true,
    },
    {
      name: 'Profile',
      link: '/profile',
      isLogged: true,
      isUnLog: false,
    },
    {
      name: 'Contact',
      link: '/contact',
      isLogged: true,
      isUnLog: false,
    },
    {
      name: 'Referral',
      link: '/referral',
      isLogged: true,
      isUnLog: false,
    },
  ]

  constructor() { }

  ngOnInit() {
  }

  logout() {
    this.logoutClicked.emit();
  }

  closeNavigation() {
    this.closeNav.emit();
  }

}
