import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Optional, Output, Self, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ControlValueAccessor, FormsModule, NgControl, ReactiveFormsModule } from '@angular/forms';
import { Subject, takeUntil,debounceTime, distinctUntilChanged, fromEvent, Observable, map, shareReplay  } from 'rxjs';
import { FlexibleConnectedPositionStrategy, Overlay, OverlayModule, ScrollStrategy, ScrollStrategyOptions } from '@angular/cdk/overlay';

@Component({
  selector: 'mmb-web-app-shared-autocomplete',
  standalone: true,
  imports: [
    CommonModule,
    OverlayModule,
    ReactiveFormsModule,
    FormsModule
  ],
  templateUrl: './shared-autocomplete.component.html',
  styleUrls: ['./shared-autocomplete.component.scss'],
  host:{
    '[id]': 'id',
    'class': 'mmb-custom-field'
  }
})
export class SharedAutocompleteComponent implements ControlValueAccessor, OnInit,AfterViewInit, OnDestroy {

  static nextId = 0;
  readonly id = `example-tel-input-${SharedAutocompleteComponent.nextId++}`;

  @Input() bindLabel:string = null;
  @Input() bindValue:string = null;
  @Input() $itemsList:(searchParams:any) => Observable<any[]>;
  @Input() placeholder:string = null;
  @Input() label:string = null;
  @Input('Disabled') disabledByInput:boolean = false;
  @Input() mode:'strict' | 'flexible' = 'strict';
  @Input() set addNewItemsToList(items:any | any[]){
    if(!Array.isArray(items)){
      items = [items];
    }

    if(this.itemsList){
      this.itemsList.push(...items);
    } else{
      this._tempItemsList = items;
    }
  }

  @Input() set apiInitialParams(val:any){
    this._apiInitialParams = val;
    if(val){
      this._$cache = null;
      this.itemsList = [];
      this.isLoadingItems = true;
      this.getInitialItemsList();
    }
  };

  get apiInitialParams(){
    return this._apiInitialParams;
  }

  @Output() resultsList:EventEmitter<any[]> = new EventEmitter<any[]>();
  @Output() selectionChange:EventEmitter<any> = new EventEmitter<any>();
  @ViewChild('searchBox')searchBox:ElementRef<HTMLInputElement>;
  @ViewChild('parentElement')parentElement:ElementRef<HTMLElement>;

  private _value:any = null;
  private disabledByControlValueAccessor:boolean = false;
  private _unsubscribeAll:Subject<void> = new Subject<void>();
  private _limit:number = 300;
  private _apiInitialParams:any = {};
  private _tempValue:any;
  private _tempItemsList:any[] = [];

  stateChanges = new Subject<void>();
  dropdownToggled:boolean = false;
  touched:boolean = false;
  cdkPositionStrategy:FlexibleConnectedPositionStrategy;
  selectedItemLabel:string = '';
  itemsList:any[] = [];
  scrollStrategy:ScrollStrategy = this._scrollStrategyOptions.block();
  noItemsFound:boolean = false;
  isLoadingItems:boolean = true;
  _$cache:Observable<any[]>;

  onChange = (_: any) => {};
  onTouched = () => {};

  set value(val:any){
    this._value = val;
  }

  get value(){
    return this._value;
  }


  get disabled(){
    return this.disabledByControlValueAccessor || this.disabledByInput;
  }

  get required(){
    return this.ngControl.control.hasError('required');
  }

  @Input() set limit(val:number){
    this._limit = Math.min(300, val);
  }

  get limit(){
    return this._limit;
  }


  constructor(
    @Optional()@Self() public ngControl:NgControl,
    private _overlay : Overlay,
    private _scrollStrategyOptions : ScrollStrategyOptions
  ) {
    this.checkInjectedFormControl();
  }

  ngOnInit(): void {
    
  }

  getInitialItemsList(){
    this.getItemsList().subscribe(
      (itemsList) => {
        this.itemsList = itemsList;
        if(this._tempItemsList.length){
          this.itemsList.push(...this._tempItemsList);
          this._tempItemsList = [];
        }

        this.isLoadingItems = false;
        this.resultsList.emit(itemsList);
        if(this._tempValue){
          this.setLabel(this._tempValue);
        }
      }
    );
  }

  ngAfterViewInit(): void {
    this.subscribeToNgControlValueChanges();
    this.subscribeToKeyupEventChange();
    this.createPositionStrategy();
  }

  createPositionStrategy(){
    this.cdkPositionStrategy = this._overlay.position()
      .flexibleConnectedTo(this.parentElement.nativeElement)
      .withPositions([{
        originX:'start',
        originY: 'bottom',
        overlayX:'start',
        overlayY: 'top',
        offsetY: 0
      }
    ]);
  }

  subscribeToNgControlValueChanges(){
    if(this.ngControl){
      this.ngControl.control.statusChanges.pipe(takeUntil(this._unsubscribeAll))?.subscribe(() => {
        this.touched = this.ngControl.control?.touched ?? this.touched;
        this.disabledByControlValueAccessor = this.ngControl.control.disabled;
        this.stateChanges.next();
      });
    }
  }

  subscribeToKeyupEventChange(){
    fromEvent(this.searchBox.nativeElement, 'keyup')
    .pipe(
      debounceTime(300),
      distinctUntilChanged(),
      takeUntil(this._unsubscribeAll)
    )
    .subscribe((event: any) => {
      const searchTerm = event.target.value;
      if(searchTerm !== null){
        this.getItemsList().subscribe();
      }
    });
  }

  getItemsList():Observable<any[]>{
    if (!this._$cache) {
      this._$cache = this.$itemsList(this.apiInitialParams).pipe(
        takeUntil(this._unsubscribeAll),
        shareReplay(1)
      )
    }
    return this._$cache.pipe(
      map(itemsList => {
        if(this.selectedItemLabel === ''){
          return itemsList.map(item => {
            item.hidden = false;
            return item;
          });
        } else{
          let count = 0;
          const items =  itemsList.map(item => {
            if(item[this.bindLabel]?.toLowerCase()?.startsWith(this.selectedItemLabel.toLowerCase())){
              item.hidden = false;
            } else{
              item.hidden = true;
              count++
            }
            return item;
          });
          this.noItemsFound = count >= itemsList.length;
          return items;
        }
      })
    );
    // this.$itemsList(searchQuery, this.limit).pipe(takeUntil(this._unsubscribeAll)).subscribe(
    //   (res) => {
    //     this.itemsList = res;
    //   }
    // );
  }

  checkInjectedFormControl(){
    if (this.ngControl) {
      this.ngControl.valueAccessor = this;
    }
  }

  writeValue(newVal: any): void {
    debugger;
    this._value = newVal;
    if (!newVal || newVal === '') {
      setTimeout(() => {
        this.selectedItemLabel = '';
      }, 100);
    } else{
      this.setLabel(newVal);
    }
    this.selectionChange.emit(newVal);
    this.onChange(newVal);
    this.stateChanges.next();
  }

  setLabel(value:any){
    if(!this.itemsList.length){
      this._tempValue = value;
      return;
    }
    let item;
    if(this.label === 'country'){
      debugger;
    }
    console.log(value);
    if(this.bindValue){
      item = this.itemsList.find(el => el[this.bindValue] === value);
    } else{
      item = JSON.parse(JSON.stringify(this.itemsList)).map(el => {
        delete el.hidden;
        return el;
      }).find(el => JSON.stringify(el) === JSON.stringify(value));

      console.log(this.itemsList);
    }
    if(item){
      this.selectedItemLabel = item[this.bindLabel] ? item[this.bindLabel] : this.mode === 'strict' ? '' : value;
    } else{
      if(this.mode === 'strict'){
        this.selectedItemLabel = '';
      } else{
        this.selectedItemLabel = value;
      }
    }

    this._tempValue = null;
  }
  
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
    this.stateChanges.next();
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabledByControlValueAccessor = isDisabled;
    this.stateChanges.next();
  }

  get errorState(): boolean {
    return Boolean(this.value) && this.touched;
  }

  fetchNearestValue(){
    if(this.selectedItemLabel && this.selectedItemLabel !== ''){
      const nearestItem = this.itemsList.find(el => el[this.bindLabel]?.toLowerCase()?.startsWith(this.selectedItemLabel.toLowerCase()));
      if(nearestItem){
        if(this.bindValue){
          this.writeValue(nearestItem[this.bindValue]);
        } else{
          this.writeValue(nearestItem);
        }
        this.selectedItemLabel = nearestItem[this.bindLabel];
      } else{
        if(this.mode === 'strict'){
          this.writeValue(null);
          this.selectedItemLabel = '';
        } else{
          this.writeValue(this.selectedItemLabel);
        }
      }
    } else{
      this.writeValue(null);
      this.selectedItemLabel = '';
    }
    this.toggleDropdown();
  }

  toggleDropdown(){
    if(this.disabled){
      return;
    }
    this.dropdownToggled = !this.dropdownToggled;
    if(!this.dropdownToggled){
      this.touched = true;
      this.onTouched();
    } else{
      this.searchBox.nativeElement.focus();
    }
  }

  onSelectValue(value:any){
    const newVal = this.bindValue ? value[this.bindValue] : value;
    this.writeValue(newVal);
    let item;
    if(this.bindValue){
      item = this.itemsList.find(el => el[this.bindValue] === newVal);
    } else{
      item = this.itemsList.find(el => JSON.stringify(el) === JSON.stringify(newVal));
    }
    if(item){
      this.selectedItemLabel = item[this.bindLabel] ?? '';
    } else{
      this.writeValue(null);
      this.selectedItemLabel = '';
    }
    this.toggleDropdown();
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
    this.stateChanges.complete();
  }
}
