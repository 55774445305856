import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'mmb-web-app-no-results-found',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './no-results-found.component.html',
  styleUrls: ['./no-results-found.component.scss']
})
export class NoResultsFoundComponent {

  @Input() message:string = 'No results found';

}
