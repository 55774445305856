import { Component, Input } from '@angular/core';
import { expandCollapse } from 'themes/mmb-web-v1-theme/animations/expand-collapse';

@Component({
  selector: 'mmb-web-app-accordion-container',
  templateUrl: './accordion-container.component.html',
  animations:[expandCollapse]
})
export class AccordionContainerComponent {


  @Input() title: string;
  @Input() description: string;
  @Input() isOpen: boolean;

}
