// Core Modules
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

// 3rd Party Modules
import { ToastrService } from 'ngx-toastr';

// Services
import { OpportunityService } from '../../opportunity/opportunity.service';

// Models
import { Opportunity } from '../../opportunity/types/opportunity.model';
import { AuthService } from '../../public/auth.service';
import { LoginModalComponent } from '../login-modal/login-modal.component';
import { RegisterComponent } from '../register/register.component';
import { SharedHelperService } from '../shared-helper.service';
import { GLOBAL_SETTINGS } from '../types/global-setting.const';

@Component({
  selector: 'mmb-web-app-user-summary-info',
  templateUrl: './user-summary-info.component.html'
})
export class UserSummaryInfoComponent implements OnInit {
  @Input() image: string;
  @Input() name: string;
  @Input() professionalTitle: string;
  @Input() company: string;
  @Input() type: string;
  @Input() createdOnDate: Date;
  @Input() id: number;
  @Input() isUser: boolean;
  @Input() popoverSummary: string;
  @Input() userUrl: string;
  @Input() orgUrl: string;
  @Input() redirect: string;
  @Input() isSaved: boolean;
  @Input() isDetailpage: boolean;
  @Input() isSaveDisabled: boolean;
  @Input() opportunity: Opportunity;
  @Input() badge: string;
  @Input() truncate: any = GLOBAL_SETTINGS.opportunity.truncate;
  @Output() addBookmark: EventEmitter<any> = new EventEmitter<any>();
  @Output() removeBookmark: EventEmitter<any> = new EventEmitter<any>();

  /**
  * constructor
  * @param OpportunityService: opportunityService
  * @param ToastrService: toastr
  */
  constructor(
    private opportunityService: OpportunityService,
    private toastr: ToastrService,
    private authService: AuthService,
    private modalService: NgbModal,
    private sharedHelperService: SharedHelperService
  ) { }

  /**
 * Life Cycle Methods
 */
  ngOnInit() {
  }

  /**
   * Add forum item to favorites
   * @param {any} event
   * @param {Opportunity} opportunity
   */
  onAddToFavorites(opportunity: Opportunity, event: any) {
    if (!this.authService.isAuthenticated$.value) {
      this.sharedHelperService.openRegisterModal(null, null, 'You\'re trying to save this opportunity');
    } else {
      this.addBookmark.emit(opportunity);
    }
    event.stopPropagation();
  }

  /**
   * Remove forum item from favorites
   * @param {any} event
   * @param {Opportunity} opportunity
   */
  onRemoveFromFavorites(opportunity: Opportunity, event: any) {
    if (!this.authService.isAuthenticated$.value) {
      this.sharedHelperService.openRegisterModal();
    } else {
      this.removeBookmark.emit(opportunity);
    }
    event.stopPropagation();
  }

  getBookmarkTooltip() {
    if (!this.authService.isAuthenticated$.value) {
      return 'Sign up or Log in to use this ';
    }
    return '';
  }

  getRemoveBookmarkTooltip() {
    if (!this.authService.isAuthenticated$.value) {
      return 'Sign up or Log in to use this ';
    }
    return '';
  }
}
