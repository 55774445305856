import { Component, OnInit, ViewChildren, ElementRef, QueryList, EventEmitter, Input, Output, Inject } from '@angular/core';
import { AuthService } from '../../../../public/auth.service';
import { UntypedFormGroup, UntypedFormControl, Validators, FormArray, UntypedFormBuilder, ValidatorFn } from '@angular/forms';
import { SharedService } from 'projects/mmb-web-app/src/app/shared/shared.service';
import { ToastrService } from 'ngx-toastr';
import { Career } from 'projects/mmb-web-app/src/app/shared/types/career.model';
import { MmbEvent } from 'projects/mmb-web-app/src/app/events/types/event.model';
import { Initiative } from 'projects/mmb-web-app/src/app/initiatives/types/initiative.model';
import { SHARE_TYPE } from '../../../types/share-type.enum';
import { SharedHelperService } from 'projects/mmb-web-app/src/app/shared/shared-helper.service';
import { fadeAnimation } from 'themes/mmb-web-v1-theme/animations/fade-in-out';
import { FORM_ERROR_FN, FormValidationFunction } from 'projects/mmb-web-app/src/app/shared/common-form-components/form-field-validation';


@Component({
  selector: 'mmb-web-app-invite-form',
  templateUrl: './invite-form.component.html',
  animations:[fadeAnimation]
})
export class InviteFormComponent implements OnInit {
  @Input() isOpenedInModal: boolean;
  @Input() career: Career;
  @Input() event: MmbEvent;
  @Input() experience: Initiative;

  @Output() completed: EventEmitter<any> = new EventEmitter<any>();
  isUserLoggedIn: boolean = false;
  totalReferrals: number[] = [1, 2, 3];
  maxReferrals: number = 6;
  isFormSubmitting: boolean = false;
  @ViewChildren('referralEmail') referralEmails!: QueryList<ElementRef<HTMLInputElement>>;
  @ViewChildren('referralName') referralNames!: QueryList<ElementRef<HTMLInputElement>>;
  referralForm: UntypedFormGroup;

  constructor(
    private authService: AuthService,
    private sharedService: SharedService,
    private toastr: ToastrService,
    private formBuilder: UntypedFormBuilder,
    private sharedHelperService: SharedHelperService,
    @Inject(FORM_ERROR_FN) public validateField :FormValidationFunction
  ) {
    this.authService.isAuthenticated$.subscribe((value: boolean) => {
      this.isUserLoggedIn = value;
    });
    this.referralForm = this.formBuilder.group({});

    for (let i = 0; i < this.totalReferrals.length; i++) {
      const emailValidators = i === 0 ? [Validators.required, Validators.email] : [Validators.email];
      const nameValidators = i === 0 ? [Validators.required] : [];

      this.referralForm.addControl(`referral_email_${i}`, new UntypedFormControl('', emailValidators));
      this.referralForm.addControl(`referral_name_${i}`, new UntypedFormControl('', nameValidators));
    }


    this.referralForm.addControl('referrer_name', new UntypedFormControl('', this.isUserLoggedIn ? [] : [Validators.required]));
    this.referralForm.addControl('referrer_email', new UntypedFormControl('', this.isUserLoggedIn ? [] : [Validators.required, Validators.email]));
    this.referralForm.addControl('custom_message', new UntypedFormControl(''));
  }

  ngOnInit() {

  }

  onClose() {
    this.completed.emit();
  }

  /**
  * Remove referral
  * @param {number} index
  * @returns {void}
  * @memberof InviteFormComponent
  * */

  _removeEmailField(index: number) {
    // Remove the row from the totalReferrals array
    this.totalReferrals.splice(index, 1);

    // Remove the corresponding FormControl instances from the form
    this.referralForm.removeControl('referral_name_' + index);
    this.referralForm.removeControl('referral_email_' + index);

    // Update the index values for the remaining rows
    for (let i = index; i < this.totalReferrals.length; i++) {
      const controlName = 'referral_name_' + (i + 1);
      const controlEmail = 'referral_email_' + (i + 1);
      if (this.referralForm.contains(controlName)) {
        const control = this.referralForm.get(controlName);
        this.referralForm.removeControl(controlName);
        this.referralForm.addControl('referral_name_' + i, control);
      }
      if (this.referralForm.contains(controlEmail)) {
        const control = this.referralForm.get(controlEmail);
        this.referralForm.removeControl(controlEmail);
        this.referralForm.addControl('referral_email_' + i, control);
      }
    }
  }


  /**
   * Add referral
   * @returns {void}
   * @memberof InviteFormComponent
   * */
  _addReferral() {
    if (this.totalReferrals.length < this.maxReferrals) {
      let newIndex = this.totalReferrals.length;
      this.totalReferrals.push(newIndex);
      let newReferralName = "referral_name_" + newIndex;
      let newReferralEmail = "referral_email_" + newIndex;
      this.referralForm.addControl(newReferralName, new UntypedFormControl(''));
      this.referralForm.addControl(newReferralEmail, new UntypedFormControl('', Validators.email));

    } else {
      this.toastr.error('oops! Max Referral count reached!', 'Error');
    }
  }

  /** 
   * Submit referral form
   * @param {Event} event
   * @returns {void}
   * @memberof InviteFormComponent
   * */

  _submitReferralForm() {
    let referralData = this._buildReferralPayload();

    if (this.career) {
      // user is on career referral - refer career
      for(const ref of referralData) {
        let payload = {
          referral: ref,
          share_type: SHARE_TYPE.EMAIL,
          career: this.career.id
        }
        this._referCareerToMembers(payload);
      }
      return;
    }

    if (this.event) {
      // user is on event referral - refer event
      for(const ref of referralData) {
        let payload = {
          referral: ref,
          share_type: SHARE_TYPE.EMAIL,
          event: this.event.id
        }
      this._referEventToMembers(payload);
      }
      return;
    }

    if (this.experience) {
      // user is on experience referral - refer experience
      for(const ref of referralData) {
        let payload = {
          referral: ref,
          share_type: SHARE_TYPE.EMAIL,
          initiative: this.experience.id
        }
        this._referExperienceToMembers(payload);
      }
      return;
    }

    // submit referral data 
    this._referSiteToMembers(referralData);
  }

  /**
   * Builds referral payload
   * @returns 
   */
  private _buildReferralPayload() {
    // if form is invalid, console log the errors
    if (this.referralForm.invalid) {
      this.referralForm.markAllAsTouched();
      return;
    }
    const referralData = [];
    const referralEmails = this.referralEmails.toArray()
      .map((elementRef: ElementRef<HTMLInputElement>) => elementRef.nativeElement.value.trim())
      .filter(Boolean);

    const referralNames = this.referralNames.toArray()
      .map((elementRef: ElementRef<HTMLInputElement>) => elementRef.nativeElement.value.trim())
      .filter(Boolean);

    let payload = {
      referrer_name: this.isUserLoggedIn ? this.authService.user$.value.first_name : this.referralForm.get('referrer_name').value,
      referrer_email: this.isUserLoggedIn ? this.authService.user$.value.email : this.referralForm.get('referrer_email').value,
      referral_email: '',
      referral_name: '',
      custom_message: this.referralForm.get('custom_message').value,
    };

    if (referralEmails.length > 1) {
      for (let i = 0; i < referralEmails.length; i++) {
        const newPayload = { ...payload, referral_email: referralEmails[i], referral_name: referralNames[i] };
        referralData.push(newPayload);
      }
    } else {
      payload.referral_email = referralEmails[0];
      payload.referral_name = referralNames[0];
      referralData.push(payload);
    }
    return referralData;
  }

  /**
   * Refer career to invited members
   * @param payload 
   */
  private _referCareerToMembers(payload: any) {
    // send the form to api and then reset the form fields
    this.sharedService.submitCareerReferralForm(payload, this.isUserLoggedIn).subscribe(
      (resp) => this._successHandler(),
      (error) => this._errorHandler(error)
    );
  }

  /**
   * Refer event to invited members
   * @param payload 
   */
  private _referEventToMembers(payload: any) {
    this.sharedService.submitEventReferralForm(payload, this.isUserLoggedIn).subscribe(
      (resp) => this._successHandler(),
      (error) => this._errorHandler(error)
    );
  }

  /**
   * Refer experience to invited members
   * @param payload
   */
  private _referExperienceToMembers(payload: any) {
    this.sharedService.submitExperienceReferralForm(payload, this.isUserLoggedIn).subscribe(
      (resp) => this._successHandler(),
      (error) => this._errorHandler(error)
    );
  }

  /**
   * User is on refer website page - submit to referral api
   * @param payload
   */
  private _referSiteToMembers(payload: any)  {
    // send the form to api and then reset the form fields
    this.sharedService.submitReferralForm(payload, this.isUserLoggedIn).subscribe(
      (resp) => this._successHandler(),
      (error) => this._errorHandler(error)
    );
  }

  private _successHandler() {
    this.referralForm.reset();
    this.isFormSubmitting = false;
    this.toastr.success('Form submitted successfully!', 'Success');
    if (this.isOpenedInModal) {
      this.onClose();
    }
  }

  private _errorHandler(err: any) {
    this.referralForm.markAllAsTouched();
    this.sharedHelperService.handleError(err);
  }

}
