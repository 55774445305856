import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalRegisterEventComponent } from '../../events/modal-register-event/modal-register-event.component';
import { MmbEvent } from '../../events/types/event.model';
import { AuthService } from '../../public/auth.service';
import { LoginModalComponent } from '../login-modal/login-modal.component';
import { RegisterComponent } from '../register/register.component';
import { SharedHelperService } from '../shared-helper.service';
import { APP_MODULE } from '../types/app-module.enum';
import { APP_SOURCE } from '../types/app-source.enum';
import { INTEREST_EVENT_TYPE } from '../types/interest-event-types.enum';
import { UserTrackersService } from '../user-trackers.service';

@Component({
  selector: 'mmb-web-app-event-card',
  templateUrl: './event-card.component.html'
})
export class EventCardComponent implements OnInit {
  @Input() event: MmbEvent;
  @Input() image: string;
  @Input() coverImage: string;
  @Input() url: string;
  @Input() title: string;
  @Input() heading: string;
  @Input() description: string;
  @Input() date: string;
  @Input() location: string;
  @Input() author: string;
  @Input() isSaved: boolean;
  @Input() isAuthenticated: boolean;
  @Input() isCommentSectionEnabled: boolean;
  @Input() isActionsEnabled: boolean = true;
  @Input() isCategorizedInSimilar: boolean = true;
  @Input() badge: string;

  @Output() addBookmark: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() removeBookmark: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() addComment: EventEmitter<any> = new EventEmitter<any>();
  @Output() commentUpvote: EventEmitter<any> = new EventEmitter<any>();
  @Output() commentDownvote: EventEmitter<any> = new EventEmitter<any>();

  constructor(private router: Router,
    private modalService: NgbModal,
    private authService: AuthService,
    private userTrackersService: UserTrackersService,
    private sharedHelperService: SharedHelperService) { }

  ngOnInit() {
  }

  /**
   * Add to favorites
   */
  onAddToFavorites(ev: any) {
    ev.stopPropagation();
    this.onActionTrack('bookmark');
    if (!this.authService.isAuthenticated$.value) {
      this.sharedHelperService.openRegisterModal('/events', null, 'You\'re trying to save this opportunity');
    } else {
      this.addBookmark.emit();
    }
  }

  /**
   * Remove from favorites
   */
  onRemoveFromFavorites(ev: any) {
    ev.stopPropagation();
    this.onActionTrack('remove-bookmark');
    if (!this.authService.isAuthenticated$.value) {
      this._openRegisterModal();
    } else {
      this.removeBookmark.emit();
    }
  }

  getShareUrl() {
    return `${window.location.href}/${this.event.slug}`;
  }

  // redirectToDetails(slug) {
  //   this.router.navigate([`/events/${slug}`]);
  // }

  /**
   * Register for event
   */
  onExternalApply(isAppliedBefore: boolean, ev: any) {
    const modalRef = this.modalService.open(ModalRegisterEventComponent, { centered: true, size: 'md' });
    modalRef.componentInstance.orgLogo = this.event.organisation.image_logo_large;
    modalRef.componentInstance.orgName = this.event.organisation.name;
    modalRef.componentInstance.eventId = this.event.id;
    modalRef.componentInstance.eventName = this.event.title;
    modalRef.componentInstance.event = this.event;
    modalRef.componentInstance.redirectUrl = this.event.website_redirect_url;
    modalRef.componentInstance.submitted.subscribe(() => {
      modalRef.dismiss();
      this.event.applied_external = {};
    });
    if (ev) {
      ev.stopPropagation();
    }
  }

  // redirectToEvent(slug: string, ev: any) {
  //   this.onActionTrack('register');
  //   if (!this.authService.isAuthenticated$.value) {
  //     ev.stopPropagation();
  //     this._openRegisterModal();
  //   }
  //   this.redirectToDetails(slug);
  // }


  onViewPortChange(ev) {
    if (ev && ev.visible) {
      const data = {
        object_uuid: this.event.uuid,
        name: `${this.event.organisation.name} - ${this.event.title}`,
        slug: this.event.slug,
        area: this.isCategorizedInSimilar ? 'listing-similar' : 'Event',
        type: 'In View',
        additional_info: 'event-listing-logged'
      };
      this.userTrackersService.onImpressions(data).subscribe();
    }
  }

  onActionTrack(buttonName: string) {
    const data = {
      object_uuid: this.event.uuid,
      name: `${this.event.organisation.name} - ${this.event.title} - ${buttonName}`,
      slug: this.event.slug,
      area: 'Event',
      type: 'Click',
      additional_info: 'event-listing-logged'
    };
    this.userTrackersService.onAction(data).subscribe();
  }

  onMoreDetails(ev: any) {
    this.sharedHelperService.openRegisterModal();
    if (ev) {
      ev.stopPropagation();
    }
  }

  onAddComment(ev) {
    this.addComment.emit(ev);
  }

  onCommentUpvote(ev) {
    this.commentUpvote.emit(ev);
  }

  onCommentDownvote(ev) {
    this.commentDownvote.emit(ev);
  }

  private _openRegisterModal() {
    const modalRef = this.modalService.open(RegisterComponent, {
      centered: true,
      size: 'md'
    });
    modalRef.componentInstance.isOpenedInModal = true;
    modalRef.componentInstance.completed.subscribe(() => {
      modalRef.dismiss();
    });
  }

  getRegisterTooltip() {
    if (!this.authService.isAuthenticated$.value) {
      return 'Sign up or Log in to use this feature';
    }
  }
}
