import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'mmb-web-app-social-panel',
  templateUrl: './social-panel.component.html'
})
export class SocialPanelComponent implements OnInit {
  @Input() item;
  @Output() share: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() bookmark: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() removeBookmark: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor() { }

  ngOnInit() {
  }

  onShareClicked() {
    this.share.emit();
  }

  onBookmarkClicked() {
    this.bookmark.emit();
  }

  onRemoveBookmarkClicked() {
    this.removeBookmark.emit();
  }

}
