import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'mmb-web-app-blockquote',
  templateUrl: './blockquote.component.html'
})
export class BlockquoteComponent implements OnInit {
  @Input() value: string;
  constructor() { }

  ngOnInit() {
  }

}
