import { Directive, ElementRef, Input, OnInit, Renderer2 } from '@angular/core';

@Directive({
  selector: '[mmbMenuButton]',
  standalone: true
})
export class MenuButtonDirective implements OnInit {

  @Input() set active(val:boolean){
    if(val){
      this._renderer.addClass(this._elementRef.nativeElement, 'mmb-border-b-3');
      this._renderer.addClass(this._elementRef.nativeElement, 'mmb-border-primary-600');
      this._renderer.addClass(this._elementRef.nativeElement, 'mmb-text-primary-600');
    } else{
      this._renderer.removeClass(this._elementRef.nativeElement, 'mmb-border-b-3');
      this._renderer.removeClass(this._elementRef.nativeElement, 'mmb-border-primary-600');
      this._renderer.removeClass(this._elementRef.nativeElement, 'mmb-text-primary-600');
    }
  }

  constructor(
    private _elementRef: ElementRef, 
    private _renderer: Renderer2
  ) {}

  ngOnInit(): void {
    this.addUtilityClasses();
  }

  private addUtilityClasses(): void {
    const tailwindClasses = [
      'mmb-p-2',
      'mmb-text-theme_black-900',
      'mmb-caption',
      'mmb-cursor-pointer',
      'mmb-flex',
      'mmb-flex-row',
      'mmb-justify-start',
      'mmb-items-center',
      'mmb-gap-2',
      'hover:mmb-text-primary-600',
      'mmb-outline-none'
    ];

    tailwindClasses.forEach((className) =>
      this._renderer.addClass(this._elementRef.nativeElement, className)
    );
  }
}
