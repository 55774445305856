import { Component } from '@angular/core';
import { CommonModule, NgClass, NgIf } from '@angular/common';
import { CdkMenuModule } from '@angular/cdk/menu';
import { OverlayModule } from '@angular/cdk/overlay';

@Component({
  selector: 'mmb-web-app-menu',
  standalone: true,
  imports: [CommonModule, NgClass, NgIf, CdkMenuModule, OverlayModule],
  templateUrl: './menu.component.html'
})
export class MenuComponent {

  menuCollapsed:boolean = true;

  toggleMenu(){
    this.menuCollapsed = !this.menuCollapsed;
  }

}
