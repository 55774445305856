import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { User } from '../../profile/types/user.model';
import { AuthService } from '../../public/auth.service';
import { LoginModalComponent } from '../login-modal/login-modal.component';
import { RegisterComponent } from '../register/register.component';
import { SharedHelperService } from '../shared-helper.service';
import { SharedService } from '../shared.service';
import { APP_MODULE } from '../types/app-module.enum';
import { APP_SOURCE } from '../types/app-source.enum';
import { Career } from '../types/career.model';
import { INTEREST_EVENT_TYPE } from '../types/interest-event-types.enum';
import { MMB_ACTIONS } from '../types/_app-actions.enum';
import { UserTrackersService } from '../user-trackers.service';
import { CareerApplication } from '../../careers/types/career-application.model';
import { STATUS_CANDIDATE } from '../../careers/helpers/career-summary-info/career-summary-info.component';
import { ApplyCareerService } from '../../careers/apply/apply.service';

@Component({
  selector: 'mmb-web-app-listing-card',
  templateUrl: './listing-card.component.html'
})
export class ListingCardComponent implements OnInit {
  profilePic: string;
  @Input() card: Career;
  @Input() userProfile: User;
  @Input() isAuthenticated: boolean;
  @Input() isCommentSectionEnabled: boolean;
  @Input() isActionsEnabled: boolean = true;
  @Input() isCategorizedInSimilar: boolean = true;
  @Input() badge: string;
  @Input() isFeed: boolean;

  @Output() addBookmark: EventEmitter<any> = new EventEmitter<any>();
  @Output() removeBookmark: EventEmitter<any> = new EventEmitter<any>();
  @Output() expressInterest: EventEmitter<any> = new EventEmitter<any>();
  @Output() addComment: EventEmitter<any> = new EventEmitter<any>();
  @Output() deleteComment: EventEmitter<any> = new EventEmitter<any>();
  @Output() updateComment: EventEmitter<any> = new EventEmitter<any>();
  @Output() commentUpvote: EventEmitter<any> = new EventEmitter<any>();
  @Output() commentDownvote: EventEmitter<any> = new EventEmitter<any>();

  constructor(private modalService: NgbModal,
    private authService: AuthService, private router: Router,
    private userTrackersService: UserTrackersService,
    private sharedHelperService: SharedHelperService,
    private applyService: ApplyCareerService) {
  }

  ngOnInit() {
  }

  /**
* Returns true if listing type is internal
*/
  isInternal(value: number): boolean {
    return value >= 1 && value <= 3;
  }

  /**
   * Returns true if listing type is exteral
   */
  isExternal(value: number): boolean {
    return value >= 4 && value <= 6;
  }

  /**
   * Add bookmark
   */
  onBookmark(ev) {
    if (!this.authService.isAuthenticated$.value) {
      this.sharedHelperService.openRegisterModal('/careers', null, 'You\'re trying to save this opportunity');
    } else {
      this.addBookmark.emit();
    }
    this.onActionTrack('bookmark');
  }

  /**
   * Remove bookmark
   */
  onRemoveBookmark(ev) {
    if (!this.authService.isAuthenticated$.value) {
      this.sharedHelperService.openRegisterModal();
    } else {
      this.removeBookmark.emit();
    }
    this.onActionTrack('remove-bookmark');
  }

  onClickView() {
    if (this.isInternal(this.card.listing_type)) {
      this.redirectToApplication(this.card);
    } else if (this.isExternal(this.card.listing_type)) {
      this.onViewDetails(this.card);
    }
  }

  onViewDetails(career: Career) {
    this.router.navigate([`/careers/${career.slug}`]);
  }

  /**
   * Express interest
   */
  onExpressInterest(ev: any) {
    if (this.authService.isAuthenticated$.value) {
      this.expressInterest.emit();
      ev.stopPropagation();
    } else {
      this.sharedHelperService.openRegisterModal(MMB_ACTIONS.EXPRESS_INTEREST);
    }
    this.onActionTrack('interest');
  }


  onViewPortChange(ev) {
    if (ev && ev.visible) {
      const data = {
        object_uuid: this.card.uuid,
        name: `${this.card.organisation.name} - ${this.card.title}`,
        slug: this.card.slug,
        area: this.isCategorizedInSimilar ? 'listing-similar' : 'Career',
        type: 'In View',
        additional_info: 'card-listing-logged'
      };
      this.userTrackersService.onImpressions(data).subscribe();
    }
  }

  // redirectToDetails(card) {
  //   this.router.navigate([`/careers/${card.slug}`]);
  //   this.onActionTrack('apply');
  // }

  redirectToApplication(card) {
    if (!card.drafted) {
      const application: CareerApplication = {
        career: card.id,
        status_candidate: STATUS_CANDIDATE.PENDING
      };
      this.applyService.submitApplication(application).subscribe(res => {
        this.router.navigate([`/careers/${card.slug}/apply`]);
      });
    } else {
      this.router.navigate([`/careers/${card.slug}/apply`]);
    }
    this.onActionTrack('apply');
  }

  redirectToOrganisation(organisation) {
    this.router.navigate([`/organisation/${organisation.slug}/profile`]);
  }


  onActionTrack(buttonName: string) {
    const data = {
      object_uuid: this.card.uuid,
      name: `${this.card.organisation.name} - ${this.card.title} - ${buttonName}`,
      slug: this.card.slug,
      area: 'Career',
      type: 'Click',
      additional_info: 'card-listing-logged'
    };
    this.userTrackersService.onAction(data).subscribe();
  }

  getExpressInterestTooltip() {
    if (this.authService.isAuthenticated$.value) {
      return 'Be considered for early screening';
    }
    return 'Sign up or Log in to use this feature';
  }

  onAddComment(ev) {
    this.addComment.emit(ev);
  }

  onDeleteComment(comment: any) {
    this.deleteComment.emit(comment);
  }

  onUpdateComment(comment: any) {
    this.updateComment.emit(comment);
  }

  onCommentUpvote(ev) {
    this.commentUpvote.emit(ev);
  }

  onCommentDownvote(ev) {
    this.commentDownvote.emit(ev);
  }
}
